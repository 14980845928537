import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';

ClassNameGenerator.configure(componentName => componentName.replace('Mui', 'V5')); // load as early as possible this code in a js file

/* eslint-disable react/prop-types */
import React from 'react';

import headerModule from '@next-common/header';
import { configService as configurationService } from '@next-common/config-service';

import { getServerSidePropsLoader, regroupModuleReducers, Context, GetServerSidePropsReturn } from '@next-common/redux-apollo-helper';
import { renderReact } from '../../fragmentHelper/renderReactCustom';

import { ReduxApolloFragmentWrapperChildren } from '../../fragmentHelper/Wrapper';

import { createQuery } from './PageQuery';
import { GetServerSidePropsHypernovaWatchContext, PagePropsType } from '../../types';

import '@next-common/header/build/esm/style.global.css';

export { headerModule };

const config = configurationService.get();

const { HeaderContainer } = headerModule.containers;

const graphQlQuery = createQuery(
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headerModule,
  },
  {
    // includePersonalQuery: config.featureFlipping.ssrPersonalize,
    // includePersonalQuery: typeof window !== 'undefined',
    includePersonalQuery: config.featureFlipping.ssrPersonalize || typeof window !== 'undefined',
    // includePersonalQuery: true,
  }
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const reducers = regroupModuleReducers([headerModule]);

const WrappedHeader: React.FC<PagePropsType> = ({ initialApolloState, initialReduxState, moduleName }) => {
  const graphQlvariables = {};

  return (
    <ReduxApolloFragmentWrapperChildren
      initialApolloState={initialApolloState}
      initialReduxState={initialReduxState}
      moduleName={moduleName}
      reducers={reducers}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore dynamic import */}
      <HeaderContainer PERSONALIZED_QUERY_OPTION={graphQlvariables} PERSONALIZED_QUERY={graphQlQuery} />
    </ReduxApolloFragmentWrapperChildren>
  );
};

export const renderFunction = renderReact('Header', WrappedHeader);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const modules = [headerModule];

export const serverCanCache = !config.featureFlipping.ssrPersonalize;

export const getServerSideProps = async (watchContext: GetServerSidePropsHypernovaWatchContext): Promise<GetServerSidePropsReturn> => {
  const baseUrl = null;
  const preloadedConfig = null;
  const graphQlVariables = {};

  const { initialReduxState, initialApolloState } = await getServerSidePropsLoader(
    watchContext,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    modules,
    baseUrl,
    preloadedConfig,
    graphQlQuery,
    graphQlVariables
  );
  return {
    props: {
      initialReduxState,
      initialApolloState,
    },
  };
};
