import gql from 'graphql-tag';

import { DocumentNode } from 'graphql';
import { Options, Modules } from '@next-common/redux-apollo-helper';


export const createQuery = ({
  headerModule,
}: Modules,
{
  includePersonalQuery = false,
}: Options): DocumentNode | undefined => {
  let PAGE_QUERY: DocumentNode | undefined;
  if (includePersonalQuery) {
    PAGE_QUERY = gql`query headerQuery {
        cartNotifications {
            ...HeaderCartNotification
        }
        profile {
            ...HeaderProfile
        }
        priceClub {
            ...HederPriceClub
        }
    }
    ${headerModule?.containers.HeaderContainer?.personalizedFraments.cartNotifications}
    ${headerModule?.containers.HeaderContainer?.personalizedFraments.profile}
    ${headerModule?.containers.HeaderContainer?.personalizedFraments.priceClub}
    `;
  }


  return PAGE_QUERY;
};
